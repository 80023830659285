var pawCart = {
	productDropdowns: new Array(),
	init: function()
	{
		var vat_chk = $('#register_vat_chk');
		vat_chk.click( function() {
			var status = vat_chk.is(':checked');
			var target = $('#vatDetailsWrap');
			if(status == true)
			{
				target.fadeIn();
			} else {
				target.fadeOut();
			}
		});

		var multipleCurrencyForm = $('#multipleCurrencyForm');
		if( multipleCurrencyForm.length > 0) {
			multipleCurrencyForm.hide();
			this.currencyBtns();
		}

		if($('#gallery-img-thumb').length > 0) {
			this.productImgGallery();
		}

		if($('#size_sel').length > 0) {
			var prices = $('.pricecontain big');
			$('#size_sel').on('change', function(e) {
				var selected = $(this).children(":selected").attr("id").split("_");
				prices.hide();
				$(prices[selected[1]]).fadeIn();
			});
		}

		var cart_btns = $('.add_to_cart');
		if(cart_btns.length > 0)
		{
			this.changePrice();
			this.addToCart(cart_btns);
		}

		var update_wishlist_item = $('.wishlist_btns');
		if(update_wishlist_item.length > 0)
		{
			this.updateWishlistItem();
		}

		var edit_wishlist_btn = $('.editWishlistBtn');
		if(edit_wishlist_btn.length > 0)
		{
			this.editWishlist(edit_wishlist_btn);
		}

		wishlists_btn = $('.remove_frm_wishlist');
		if( wishlists_btn.length > 0 ) {
			this.removeWishlist(wishlists_btn);
		}

		var payment_method = $('.payment_method');
		if(payment_method.length > 0)
		{
			payment_method.click( function() {
				pawCart.updatePaymentMethod(this.value);
			});
		}

		var same_as_billing = $('#same_as_billing')
		if(same_as_billing.length > 0) {
			same_as_billing.click( function() {
				pawCart.sameAsBilling();
			});
		}

		var remove_from_cart_btn = $('.remove_from_cart_btn');
		if(remove_from_cart_btn.length > 0) {
			remove_from_cart_btn.click( function(e) {
				pawCart.deleteItem(e);
			});

			var delivery_dropdown = $('.del_frm_sel option');
			if(delivery_dropdown.length > 0)
			{
				pawCart.deliverySetup(delivery_dropdown);
			}
		}

		// BASKET
		var qty_select = $('.qtyBtn');
		if(qty_select.length > 0)
		{
			qty_select.click( function(e) {
				var clicked = $(this).attr('id');
					clicked = clicked.split('_');
				var elem = $('#qty_' + clicked[2]);
				var current = elem.val();

				if(clicked[1] == 'Plus') current++;
				if(clicked[1] == 'Minus') current--;
				elem.val(current);
				elem.closest('form').trigger('submit');

			});
		}

		// Product Pages
		var qty_select = $('.qty-btns');
		if(qty_select.length > 0)
		{
			qty_select.click( function(e) {
				pawCart.updateQty(e);
			});
		}


		var multipleCurrencyForm = $('#multipleCurrencyForm');
		if(multipleCurrencyForm.length > 0)
		{
			pawCart.changeCurrency();
		}
	},

	currencyBtns: function()
	{
		var currency_sel = $('#currency_sel');
		$('#currencyWrap span').click(function(){
			var selected = $(this).attr('id').replace('currency','');
			currency_sel.val(selected);
			currency_sel.trigger('change');
		});
	},

	cartesianProductOf: function(arrays) {
	  	if (!arrays || arrays.length < 1)
        	return [];
	    else {
	        var header = arrays[0];
	        var row = arrays.slice(1);
	        var result = [];
	        for (var i = 0; i < header.length; i++) {
	            var productOfRow = this.cartesianProductOf(row);
	            if (productOfRow && productOfRow.length > 0) {
	                for (var j = 0; j < productOfRow.length; j++) {
	                    result.push([header[i]].concat(productOfRow[j]));
	                }
	            }
	            else
	                result.push([header[i]]);
	        }
	        return result;
	    }
	},

	selectSwatch: function(dropdown,color,selected)
	{
		$('#variant_sel_' + dropdown).val(color);

		// HIGHLIGHT BORDER
		$('#colorSwatches' + dropdown + ' span').removeClass('colorSwatchSelected');
		$('#colorSwatches' + dropdown + ' span#color' + selected).addClass('colorSwatchSelected');

		//var dropdowns = $('.filterCats');
		//dropdowns.trigger('change');

		$('#variant_sel_' + dropdown).trigger('change');
	},

	deliverySetup: function(options)
	{

		$('#del_btn').addClass('hidden');
		var country_sel = new Array();
		var regions_sel = new Array();
		var variant_sel = new Array();
		var x = -1;

		$.each(options, function(key, option) {
			var country = "";
			var region = "";
			var variant = "";

			var tmp = $.trim($(option).text()).split(' / ');

			if( tmp.length == 1 ) {
				country = tmp[0].split(' - ');
				variant = country[1];
				country = country[0];
			} else {
				country = tmp[1].split(' - ');
				variant = country[1]
				region = country[0];
				country = tmp[0];
			}

			country = $.trim(country);


			if( $.inArray(country, country_sel) == -1 )
			{
				country_sel.push(country);
				x++;
				regions_sel[x] = new Array();
				variant_sel[x] = new Array();
			}

			if(region != '') {
				var countryId = $.inArray(country, country_sel);
				if( $.inArray(region, regions_sel[countryId]) == -1 )
				{
					regions_sel[countryId].push(region);
				}
			}


		});

		//CREATE DROPDOWNS
		var html = '<div class="select_style">';
				html += '<select onchange="pawCart.updateDelivery()" id="country_sel"><option value="0">Select</option>';
		var additional_html = '';
			$.each(country_sel, function(key, value) {
				html += '<option value="'+(key + 1)+'">';
				value = value.split(' = ');
				html += value[0];
				html += '</option>';
				if( regions_sel[key].length > 0 )
				{
					additional_html += '<div class="select_style"><select onchange="pawCart.updateDelivery()" class="hidden region_sel" name="region_sel['+(key + 1)+']" id="region_sel_'+(key + 1)+'">';
					additional_html += '<option value="0">Select</option>';
						$.each(regions_sel[key], function(regionId, region) {
							region = region.split(' = ');
							additional_html += '<option value="'+region[0]+'">';
								additional_html += ' - ' + region[0];
							additional_html += '</option>';

						});
					additional_html += '</select></div>';
				}

			});
			html += '</select>';
		html += '</div>';

		var frm = $('#del_frm');

		frm.prepend(additional_html);
		frm.prepend(html);

		$('.del_frm_sel').attr('style', 'display:none;');

	},

	updateDelivery: function(price_sel)
	{
		$('.region_sel').addClass('hidden');

		var select = $('#country_sel option:selected');
		var subregions = $('#region_sel_' + select.val());
		var del_frm_sel = $('.del_frm_sel');
		var tmp = select.text().split('- ');
		var lookup = tmp[1];
		var trigger = false;
		var variant_sel = Array();

		if(subregions.length > 0)
		{
			$('#del_btn').addClass('hidden');
			$('#del_cost_txt').fadeOut();
			var subRegionSelect = subregions.val();
			if( subRegionSelect != 0 )
			{
				lookup += ' / ' + subRegionSelect;
				trigger = true;
			}
			subregions.removeClass('hidden');
		}
		else
		{
			trigger = true;
		}

		countryId = 0;
		if( trigger == true )
		{
			$('#del_variants_wrap').addClass('hidden'); // REMOVE VARIANTS ON COUNTRY CHANGE
			var options = $('.del_frm_sel option');
			$.each(options, function(key, row) {
				var tmp = $(row).text().split(' - ');
				var country = $.trim(tmp[0]);
					country = country.replace('- ', '');

				if(country == lookup)
				{
					variant_sel.push(tmp[1]);
				}
			});


			if( variant_sel.length > 0 )
			{
				var variant_sel_html = '<select name="variant_sel" id="variant_sel">';
					variant_sel_html += '<option value="0">Select</option>';
					$.each(variant_sel, function(key, row) {
						row = row.split(' = ');
						row = row[0]
						variant_sel_html += '<option value="'+key+'">';
						variant_sel_html += ' - ' + row;
						variant_sel_html += '</option>';
					})
					variant_sel_html += '</select>';

				var target = $('#del_variants_wrap');
					target.html(variant_sel_html);
					target.removeClass('hidden');

				$('#variant_sel').change( function() {
					// VARIANTS
					var selected = lookup + $('#variant_sel option:selected').text();
						selected = $.trim(selected);
						$.each(options, function(key, row) {
							var row = $(row);
								txt = row.text().split(' = ');
								txt = txt[0].replace('- ', '');
								txt = $.trim(txt);
								value = row.val();
								if(txt == selected)
								{
									$(".del_frm_sel option[value='"+value+"']").prop('selected','selected');
									pawCart.lookUpDeliveryCost();
								}
						});
					$('#del_btn').removeClass('hidden');
				});
			} else {
				// NO VARIANTS
				var selected = lookup;
					selected = $.trim(selected);
					$.each(options, function(key, row) {
						var row = $(row);
							txt = row.text().split(' = ');
							txt = txt[0].replace('- ', '');
							txt = $.trim(txt);
							value = row.val();
							if(txt == selected)
							{
								$(".del_frm_sel option[value='"+value+"']").prop('selected','selected');
								pawCart.lookUpDeliveryCost();
							}
					});

					$('#del_btn').trigger('click');
				//$('#del_btn').removeClass('hidden');

			}
		}


	},

	lookUpDeliveryCost: function()
	{
		/*
		var price = $('.del_frm_sel option:selected').text().split(' = ');
			price = price[1];
		if(price != '')
		{
			$('#del_cost_txt big').html(price);
			$('#del_cost_txt').fadeIn();
		}
		*/
	},

	updateWishlistItem: function()
	{
		var btns = $('.wishlist_btns span');
		btns.click( function() {
			var tmp = $(this).parents('tr').attr('id').replace('row_', '').split('_');
			var wishlist = tmp[0];
			var itemId = tmp[1];
			var clicked = $(this).attr('id');
			var action = clicked.substr(0,6);
			var url = './ajax/updateWishlistItem.inc.php?id=' + itemId + '&wishlist=' + wishlist;

			if(action == 'remove') url += '&status=0';

			$.ajax({
				url: url
			}).done( function() {
				if(action == 'remove') {
					$('#pricingSold_' + itemId).attr('style','display:none');
					$('#removePurchasedBtn' + itemId).attr('style','display:none');
					$('#pricingOption_' + itemId).fadeIn();
					$('#purchasedBtn' + itemId).fadeIn();
				} else {
					$('#pricingOption_' + itemId).attr('style','display:none');
					$('#purchasedBtn' + itemId).attr('style','display:none');
					$('#pricingSold_' + itemId).fadeIn();
					$('#removePurchasedBtn' + itemId).fadeIn();
				}
			});

		});
	},


	editWishlist: function(btns)
	{
		btns.click( function(e) {
			e.preventDefault();
			var itemId = $(this).attr('id').replace('editWishlist', '');
			var url = './ajax/editWishlist.inc.php?wishlist=' + itemId;
			$.ajax({
				cache: false,
				url: url,
				data: $(this).serializeArray(),
				success: function(data) {
					$.fancybox(data, {
						maxWidth:475,
						maxHeight:475
					});
				}
			});
		});

		// DELETE BTN
		var delete_btn = $('.deleteWishlistBtn');
		delete_btn.click( function(e) {
			e.preventDefault();
			var itemId = $(this).attr('id').replace('deleteWishlist', '');
			var agree = confirm("Are you sure you want to delete this wishlist?");
			if( agree == true )
			{
				$.ajax( {
					url: './ajax/removeWishlist.inc.php?wishlist=' + itemId
				}).done( function(data) {
					if(data == 1) {
						$('#row_' + itemId).fadeOut();
					}
				});
			}

		});
	},

	removeWishlist: function(btns)
	{
		btns.click( function(e) {
			e.preventDefault();
			var elemId = $(this).attr('id').split('_');
			var agree = confirm("Are you sure you want to remove this item from your wishlist?");
			if(agree == true)
			{
				var wishlist = elemId[1];
				var item = elemId[2];
				$.ajax({
					url: './ajax/removeCart.inc.php?pricing=' + item + '&wishlist=' + wishlist
				}).done( function(response) {
					var select = $('#row_' + response);
					select.fadeOut();
					var total = $('#num_of_items').html();
					$('#num_of_items').html(parseInt(total) - 1);
				});
			}
		});
	},

	changeCurrency: function()
	{
		$('#change_btn').attr('class','hide');
		$('#currency_sel').change( function() {
			$('#multipleCurrencyForm').submit();
		});
	},

	updatePaymentMethod: function(value)
	{
		var card_frm = $('#card_frm');
		var paypal_frm = $('#paypal_frm');

		if(value == 1) {
			paypal_frm.attr('style','display:none;');
			card_frm.fadeIn();
		} else {
			card_frm.attr('style','display:none;');
			paypal_frm.fadeIn();
		}
	},

	updateQty: function(e)
	{
		var elemId = e.target.id;
		var action = elemId.split("-");
		var pricingId = action[1];
		action = action[0];

		var currentInput = $('.product-details-qty');
		var currentValue = currentInput.val();

		if(action == 'more')
		{
			currentValue++;
		}
		else
		{
			currentValue--;
		}

		if(currentValue > 0) {
			currentInput.val(currentValue);
		}
	},

	deleteItem: function(e)
	{
		var response = confirm('Are you sure you want to remove this item from your cart?');
		if(response == false) {
			e.preventDefault();
		}
	},

	productImgGallery: function()
	{
		var imgs = $('.productFeatureImg img');
		$('#gallery-img-thumb .thumb-outter').click(function(){
			var selected = $(this).attr('id').replace('thumb','');
			imgs.addClass('hidden').removeAttr('style');
			$('#productFeatureImgInner' + selected).fadeIn();
		});

	},

	ajaxPricingFilter: function(productId,options)
	{
		var dropdowns = $('.filterCats');

		var allVariants = $('#options_sel option');
		var postdata = {};
		var priceId = 0;

		dropdowns.unbind();
		dropdowns.change( function() {

			var add_to_cart_btn = $('.add_to_cart');
			var add_to_wishlist_btn = $('.add_to_wishlist');

			// FILTER DROPDOWNS
			var updated_dropdown_id = $(this).attr('id').replace('variant_sel_', '');
			var updated_dropdown_select = $(this).val();
			var num_of_variants = dropdowns.length;

			// RESTORE DROPDOWNS
			$.each(dropdowns, function(key,row)
			{
				if(key != updated_dropdown_id)
				{
					var tmp_selected = $('#variant_sel_wrap' + key + " option:selected").val();
					$('#variant_sel_wrap' + key).html( $(pawCart.productDropdowns[key]).html() );
					$('#variant_sel_wrap' + key + " select").val(tmp_selected);

					// SWATCHES
					var look_up_swatch_color = $('#variant_sel_wrap' + key + ' span.' + tmp_selected);
					if(look_up_swatch_color.length == 1) {
						$('#variant_sel_wrap' + key + ' span').removeClass('colorSwatchSelected');
						look_up_swatch_color.addClass('colorSwatchSelected');
					}

				}
			});

			// FILTER INVALID
			var search_options = new Array();
			var x = 0;

			// GET ALL OPTIONS
			$.each(dropdowns, function(key, row) {
				search_options[key] = new Array();
				if(key != updated_dropdown_id)
				{
					var row_options = $(row).children('option');
					$.each(row_options, function(subId,sub) {
						search_options[key][subId] = sub.value;
					});
				} else {
					search_options[key][0] = $(dropdowns[updated_dropdown_id]).val();
				}
			});

			search_options = pawCart.cartesianProductOf(search_options);
			search_options_valid = new Array();


			// REMOVE INVALID
			var x = 0;
			$.each(search_options, function(key,row) {
				var tmp = row.join(' / ');
				var found = false;

				$.each(allVariants, function(varId,varVal) {
					var varVal = $(varVal).text();
					if(varVal == tmp)
					{
						found = true;
					}
				});

				if(found == true) {
					search_options_valid[x] = tmp;
					x++;
				}

			});

			// PAIR WITH DROPDOWNS
			var allDropdowns = $('#ajaxTarget option');
			var x = 0;
			$.each(dropdowns, function(key, row) {
				if(key != updated_dropdown_id)
				{
					var row_options = $(row).children('option');
					$.each(row_options, function(tmpVal,tmpOption)
					{
						var tmp_txt = $(tmpOption).text().replace(' - ', '');
						var tmp_color = tmp_txt;

						// LOOK FOR OPTION
						var found = false;

						$.each(search_options_valid, function(optId,optVal) {
							var optVal = optVal.split(' / ');
								optVal = optVal[key];
							if(optVal == tmp_txt) {
								found = true;
							}
						});

						if(found == false)
						{
							var tmp_value = $(tmpOption).val();
							$("#ajaxTarget #variant_sel_"+key+" option[value='"+tmp_value+"']").remove();
							$('#ajaxTarget #colorSwatches' + key + ' .' + tmp_color).remove();
						}
						x++;
					});
				}
			});


			// SEARCH PRICE AND THUMB
			var search_for_price = '';
			var search_for_thumb = '';
			$.each(dropdowns, function(key, value) {
				selected = $(value).val();
				search_for_price += selected + ' / ';
			});

			if(search_for_price != '')
			{
				search_for_price = search_for_price.substring(0, (search_for_price.length - 3));

				$.each($('#options_sel option'), function(key,row) {
					var row = $(row);
					var tmp_txt = row.text();
					if(tmp_txt == search_for_price)
					{
						priceId = row.val();
					}
				});

				search_for_thumb = search_for_price;
			}

			// SEARCH FOR PRODUCT DETIALS
			if(priceId > 0)
			{
				var target = $('#js-price-details-'+priceId);
				options.attr('style', 'display:none');
				if(target.length > 0) {
					target.show();
					add_to_cart_btn.show();
					add_to_wishlist_btn.show();
				} else {
					target.hide();
					add_to_cart_btn.hide();
					add_to_wishlist_btn.hide();
				}

				$('.item-specification-wrap').hide();
				var item_spec = $('#item-specification' + priceId);
				if(item_spec.length == 1)
				{
					item_spec.show();
				}

				$('.outOfStock').hide();
				$('.add_to_cart').show();
				var out_of_stock = $('#outOfStock' + priceId);
				if(out_of_stock.length == 1) {
					out_of_stock.show();
					$('.add_to_cart').hide();
				}

				$('#cat_sel').val(priceId);
				$('#qty_sel').val(1);
			}

			// SEARCH FOR THUMB
			search_for_thumb = $.trim(search_for_thumb);
			search_for_thumb = search_for_thumb.replace(' / ', '/');

			var thumbs = $('#productZoomGalleryWrapper a');
			$.each(thumbs, function(key,row) {
				var selected_thumb = $(row);
				var search_for_thumb_opt = "'" + search_for_thumb + "'";

				var val = $.trim(search_for_thumb_opt);
				if(selected_thumb.hasClass(val) == true)
				{
					thumbId = selected_thumb.attr('id').replace('thumb','');
					if(thumbId > 0) {
						$('#thumb' + thumbId).trigger('click');
						$('#productZoomGallery').trigger("slideTo", ["#thumb"+thumbId, "a"]);
					}
				}
			});

			// HIDE SELECTED
			$.each(dropdowns, function(key,row) {
				var options = $(row).children('option');
				$.each(options, function(optId,opt) {
					var optObj = $(opt);
				});
			})


			pawCart.ajaxPricingFilter(productId,options);

		});
	},

	changePrice: function()
	{

		var dropdown = $('#options_sel');
		var options = $('big.js-price-wrap');
		var productId = $('#product_sel').val();

		$('#options_sel').hide();

		$('#ajaxTarget').load('./ajax/productOptions.inc.php?id=' + productId, function() {
			pawCart.productDropdowns = $('.variant-wrap').clone();

			pawCart.ajaxPricingFilter(productId,options); // TRIGGER FIRST LOAD

			var setup_dropdown = $('.filterCats');
			var total_dropdowns = setup_dropdown.length;
			if(setup_dropdown.length > 0)
			{
				$.each(setup_dropdown, function(key,row) {
					if(key > 0)
					{
						$(row).trigger('change');
					}
				});
			}

		});

	},

	addToCart: function(btns)
	{
		var nCat = $('#cat_sel').val();
		var nQty = $('#qty_sel').val();

		var clicked = false;
		$('.add_to_cart, .add_to_wishlist').click( function() {
			clicked = $(this).attr('class');
		});

		$('#pricingfrm,.pricingfrm').bind('submit', function(e) {
			e.preventDefault();
			var url = './ajax/addToCart.inc.php';
			$.ajax({
	            type: "POST",
	            cache: false,
	            url: url,
	            data: $(this).serializeArray(),
	            success:function(data){
                  $.fancybox(data, {
                  	padding:0,
										closeBtn: false
                  });
                  $('#minicart').load('./ajax/miniCart.inc.php');
                }
        	});
		});

		return false;
	},

	sameAsBilling: function()
	{
		var chk = $('#same_as_billing').is(":checked");
		var frm = $('#del-address-frm');
		var required = $('#del_name_txt,#del_add1_txt,#del_city_txt,#del_country_txt,#del_pcode_txt,#del_phone_txt');
		if(chk == true) {
			frm.hide();
			required.removeAttr('required');
		} else {
			frm.slideDown();
			required.attr('required','required');
		}
	}
}

pawCart.init();
