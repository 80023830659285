var pawLibrary = {
	total: 0,
	page: 1,
	markers: [],
	current: {},
  createMiniMap: function(lat,lng, location_title, location_add)
  {
      var position = new google.maps.LatLng(lat, lng);
      var mapOptions = {
        center: position,
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
				draggable: false,
				scrollwheel: false
      };

      var map = new google.maps.Map(document.getElementById('stockistMap'), mapOptions);

			var contentString = '<div id="mapContent">'+
				'<p><strong>'+location_title+'</strong></p>'+
					'<p>'+location_add+'</p>'+
					'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a> &raquo;' +
				'</div>';

			var infowindow = new google.maps.InfoWindow({
				content: contentString
			});

      var marker = new google.maps.Marker({
        position: position,
        map: map,
        animation: google.maps.Animation.DROP,
				draggable: false,
				title: location_title
      });

			google.maps.event.addListener(marker, 'click', function() {
			  infowindow.open(map,marker);
			});
  },
	search: function(term)
	{
		$('#searchresults-wrap').html('<div id="loading">Searching...</div>').load('ajax/search.inc.php?term=' + term);
	},
  validateForm: function()
	{
		var errors = 0;

		var name_txt = $('#name_txt');
		var email_txt = $('#email_txt');
		var message_txt = $('#message_txt');
		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if(name_txt.val().length < 3)
		{
			name_txt.addClass('required_border');
			errors++;
		} else {
			name_txt.removeClass('required_border');
		}

		if( !filter.test(email_txt.val()) )
		{
			email_txt.addClass('required_border');
			errors++;
		} else {
			email_txt.removeClass('required_border');
		}

		if(message_txt.val().length < 3)
		{
			message_txt.addClass('required_border');
			errors++;
		} else {
			message_txt.removeClass('required_border');
		}

		return (errors == 0) ? true : false;

	},

	getTwitterProfilePic: function(elemId, username)
	{

		$.ajax({
			type: 'json',
		    url: '../ajax/getTwitter.inc.php?username=' + username,
		    beforeSend: function() {
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="./imgs/loader.gif" alt="'+username+'" class="loader" />');
		    },
		    success: function(data){
		    	var img = data[0].image;
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="'+img+'" alt="'+username+'" class="profile" />');
		    }
		});

	},

	navigateTabs: function()
	{
		var mapsLoaded = false;
		$('.showTab').click(function() {
			var tab_number = $(this).attr('id').replace('showTab', '');
			var tab_length = $('.showTab').length;

			$('.showTab').removeClass('active');
			for(x = 1; x <= tab_length; x++) {
				$('#tab' + x).hide();
			}

			$('#showTab' + tab_number).addClass('active');
			$('#tab' + tab_number).fadeIn();

			if((tab_number == 2) && (mapsLoaded == false)) {
				var mapLat = ('lat' in pawLibrary.current) ? pawLibrary.current.lat : 54.787715;
				var mapLng = ('lng' in pawLibrary.current) ? pawLibrary.current.lng : -6.492315;

				var mapOptions = {
            zoom: ('lat' in pawLibrary.current) ? 10 : 8,
            center: new google.maps.LatLng(mapLat, mapLng),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
						scrollwheel: false
        };
        map = new google.maps.Map(document.getElementById('stockists-map'),mapOptions);

				// You are here
				if('lat' in pawLibrary.current) {
					var current = new google.maps.Marker({
	            map: map,
    					animation: google.maps.Animation.DROP,
	            position: new google.maps.LatLng(pawLibrary.current.lat, pawLibrary.current.lng)
	        });
					var tmp = "<h3>You are here</h3>";
							tmp += pawLibrary.current.search;
					infoWindow = new google.maps.InfoWindow({
				    content: tmp
				  });

					current.setIcon('http://maps.google.com/mapfiles/ms/icons/blue-dot.png');

				  google.maps.event.addListener(current, 'click', function() {
				    infoWindow.open(map,current);
				  });
				}

				// Populate Markers
				var marker = new Array();
				var infoWindows = new Array();
				$.each(pawLibrary.markers, function(key,row) {
					marker[key] = new google.maps.Marker({
	            map: map,
    					animation: google.maps.Animation.DROP,
	            position: new google.maps.LatLng(row.lat, row.lng)
	        });
					var tmp = "<h3>"+row.title+"</h3>";
							tmp += row.address;
							tmp += '<br /><a href="'+row.link+'" class="btn">View Details</a>';

					infoWindows[key] = new google.maps.InfoWindow({
				    content: tmp
				  });

				  google.maps.event.addListener(marker[key], 'click', function() {
				    infoWindows[key].open(map,marker[key]);
				  });

				});

				mapLoaded = true;

			}
		});
	},

	getUrlVars: function()
	{
		 var vars = [], hash;
		 var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
		 for(var i = 0; i < hashes.length; i++)
		 {
				 hash = hashes[i].split('=');
				 vars.push(hash[0]);
				 vars[hash[0]] = hash[1];
		 }
		 return vars;
	},

  loadMoreBtn: function() {
    var readMoreBtn = $('#read-more-btn');
    if($('#stockists_list .hide').length > 0) {
      readMoreBtn.css('display', 'block').fadeIn();
    } else {
      readMoreBtn.hide();
    }
  },

	sortBranches: function() {
		var items = $('#stockists_list .item').get();
    var paging = 9;

		 items.sort(function(a,b){
			 var keyA = $(a).find('.distance').text();
			 var keyB = $(b).find('.distance').text();
			 if(keyA == '') keyA = 9999999999999999999999; // SET BLANK TO LAST
			 if(keyB == '') keyB = 9999999999999999999999; //

			 if(keyA != 9999999999999999999999) keyA = parseInt(keyA);
			 if(keyB != 9999999999999999999999) keyB = parseInt(keyB);

			 if ((Math.round(parseFloat(keyA)*100)/100) < (Math.round(parseFloat(keyB)*100)/100)) { return -1; }
			 if ((Math.round(parseFloat(keyA)*100)/100) > (Math.round(parseFloat(keyB)*100)/100)) { return 1; }

			 return 0;
		 });


		 var parent = $('#stockists_list');

		 parent.empty();
     var x = 1;
		 $.each(items, function(i, li){
       if(x > paging) $(li).addClass('hide');
       parent.append(li);
       x++;
		 });

     pawLibrary.loadMoreBtn();

     var readMoreBtn = $('#read-more-btn');
     readMoreBtn.click(function() {
        var x = 1;
        items = $('#stockists_list .hide');
        $.each(items, function(i, li){
           if(x <= paging) {
             $(li).removeClass('hide');
           }
           x++;
        });
        pawLibrary.loadMoreBtn();
     });

	},

	setMarkers: function()
	{
		var branches = $('#stockists_list .item');
		// for Map
		$.each(branches, function(key,row) {
			var row = $(row);
			var marker_lat = row.find('.latitude').html();
			var marker_lng = row.find('.longitude').html();
			var address = row.find('h4').html();
			var title = row.find('h2').html();
			var link = row.find('.btn').attr('href');
			pawLibrary.markers.push({id: key, lat: marker_lat, lng: marker_lng, title: title, address: address, link: link});
		});
	},

	branchFinder: function()
	{
		var params = pawLibrary.getUrlVars();
		var location = decodeURIComponent(params['location_txt']);
				location = location.split('+').join(' ');

		var branches = $('#stockists_list .item');

		// geolocation
		var geocoder = new google.maps.Geocoder();
		geocoder.geocode( {
		 'address': location
				 }, function(results, status) {
					 	if(status == google.maps.GeocoderStatus.OK) {
							var lat = results[0].geometry.location.lat();
							var lng = results[0].geometry.location.lng();
							var orgin = new google.maps.LatLng(lat, lng);
							pawLibrary.current = {lat: lat, lng: lng, search: location};

							$.each(branches, function(key,row) {
								var row = $(row);
								var marker_lat = row.find('.latitude').html();
								var marker_lng = row.find('.longitude').html();
								var position = new google.maps.LatLng(marker_lat, marker_lng);
								//
								var distance = (google.maps.geometry.spherical.computeDistanceBetween(orgin, position) / 1000).toFixed(2);
								var miles = distance * 0.6214;
        				row.find('.distance').html(miles.toFixed(2) + ' miles').fadeIn();

							});

							pawLibrary.sortBranches();
						}
				 });

	},

	setMap : function(elem, lat, lng, location_title, location_add, drag) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 14,
		  center: myLatlng,
          scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: drag
		}

		var map = new google.maps.Map(document.getElementById(elem), myOptions);

		var contentString = '<div id="mapContent">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a> &raquo;' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map,
			title: location_title,
			draggable: drag
		});

		if(drag == true) {
			google.maps.event.addListener(marker, 'dragend', function() {
					var point = marker.position;
					if(lat_txt) lat_txt.value = point.$a;
					if(long_txt) long_txt.value = point.ab;

				});
		}

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(map,marker);
		});
	},

	setMapByGeocode : function(elemId, address, zoomlevel) {

		var mapOptions = {
		  zoom: zoomlevel,
          scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: false
		}

		var contentString = '<div id="mapContent">'+
				'<p>'+address+'</p>'+
				'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(address)+'">Get Directions</a> &raquo;' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
            content: contentString
         });

		var geocoder = new google.maps.Geocoder();
		var map = new google.maps.Map(document.getElementById(elemId), mapOptions);

		geocoder.geocode( {
			'address': address
				}, function(results, status) {
					  if (status == google.maps.GeocoderStatus.OK) {
						map.setCenter(results[0].geometry.location);
						var marker = new google.maps.Marker({
							map: map,
							position: results[0].geometry.location
						});

						google.maps.event.addListener(marker, 'click', function() {
						  infowindow.open(map,marker);
						});

					  }
					}
		);

	},

	insertParam: function(key, value, loc)
	{
		var params = false;

		if( loc == false )
		{
		    var loc = location.href;
		    	loc += '?cat_sel=&brand_sel=&sort_sel&show=16';
		}

	    if(loc.indexOf("?") !== -1) {
	       loc = loc.split('?');
	       params = loc[1];
	       loc = loc[0];
	    }
	    loc = loc + '?';

	    if(params != false)
	    {
		    params = params.split('&');
		    var x;
		    for(x =0; x < params.length; x++)
		    {
		    	var tmp = params[x].split('=');
		    	if(tmp[0]==key) {
		    		loc += key + '=' + value + '&';
		    	} else {
		    		loc += tmp[0] + '=' + tmp[1] + '&';
		    	}
		    }
		    loc = loc.slice(0,-1);
		} else {
			loc += key + '=' + value;
		}

		return loc;
	},

	updateProductURL: function(url)
	{
		window.history.pushState(null, null, url);
	},

	mobileNav: function()
	{
		var mobileNavItems = $('#mobile-nav-items');
		var mobileBtn = $('#mobile-burger-btn');
		var pageBody = $('#page-inner-wrap');
		var secondMenu = $('ul.second');
		if( mobileBtn.length > 0 )
		{
			// Load Menu
			mobileBtn.click(function() {
				if(mobileBtn.hasClass('menu-open') == true) {
					pageBody.removeClass('push');
					mobileBtn.removeClass('menu-open');
				} else {
					pageBody.addClass('push');
					mobileBtn.addClass('menu-open');
				}
			});

			$('.triggerSubMenu').click(function(e) {
				var clicked = e.currentTarget.id.replace("showSubMenu","");
				var target = $('#subMenu' + clicked);

				secondMenu.slideUp(function() {
					secondMenu.removeClass('open');
				});

				target.slideDown(function() {
					target.addClass('open');
				});
			});
		}

		$('#mobile-nav-inner-items').hcSticky({
			bottom:0
		});
	},

	onResize: function()
	{

	},

	showMoreProducts: function()
	{
		var loader = $('#loader');
		var findMoreBtn = $('#find-more-btn');
		findMoreBtn.click(function() {
			loader.show();
			pawLibrary.page++;
			pawLibrary.productSearch();
			loader.hide();
		});
	},

	triggerCurrencies: function() {
		var currency = $('#currency-selector');
		currency.click(function() {
			var selected = $(this).find('.currency_ico');
			var id = selected.attr('id').replace('currency', '');
			$.ajax({
				url: './ajax/setCurrency.inc.php?id=' + id
			}).done(function() {
				//btns.removeClass('selected');
				//selected.addClass('selected');
				location.reload();
			});
		});
	},

	configureInlineEnquiries: function() {
		var inlineEnquiries = $('.enquiryDialog');
		inlineEnquiries.click( function() {
				var elem = $(this);
					elem = elem.find('.enquiryFormTitle');
					title = elem.html();
					title = $.trim(title);

					var subTitle = "";
					var size = $('#size_sel');

					if((size.length == 1) && (size.val() != 0)) {
							subTitle += " - " + size.val();
					}

				inlineEnquiries.fancybox({
					fitToView	: true,
					autoSize	: true,
					type: 'ajax',
					padding: 0,
					maxWidth: 530,
					width: "90%",
					beforeShow: function() {
						$('#formWrap h3').html("Enquire About " + title + subTitle);
        		$("body").css({'overflow-y':'hidden'});
					},
					afterClose: function() {
        		$("body").css({'overflow-y':'visible'});
					},
					afterShow: function() {
						var form = $('#formWrap form');
						var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						var valid = true;
						var input;

						form.submit(function(e) {
							e.preventDefault();
							valid = true;

							if(valid == true)
							{
								input = $('#formWrap form #name_txt');
								if(input.val() == '') {
									input.addClass('required');
									valid = false;
								} else {
									input.removeClass('required');
								}
							}

							if(valid == true)
							{
								input = $('#formWrap form #email_txt');
								if(re.test(input.val()) == false) {
									input.addClass('required');
									valid = false;
								} else {
									input.removeClass('required');
								}
							}

							if(valid == true)
							{
								input = $('#formWrap form #message_txt');
								if(input.val() == '') {
									input.addClass('required');
									valid = false;
								} else {
									input.removeClass('required');
								}
							}

							if(valid == true)
							{
								var loader = $('#loader');
								var data = {};
										data['name_txt'] = $('#formWrap form #name_txt').val();
										data['email_txt'] = $('#formWrap form #email_txt').val();
										data['telephone_txt'] = $('#formWrap form #telephone_txt').val();
										data['message_txt'] = $('#formWrap form #message_txt').val();
										data['pageName'] = title + subTitle;
										data['list_txt'] = "";
										data['url'] = 'http://' + window.location.hostname + window.location.pathname;

								form.hide();
								loader.fadeIn();


								$.ajax({
									type: 'POST',
									url: './ajax/enquiryFormSend.inc.php',
									data: data,
								}).done(function(response) {
									loader.html(response);
								});

							}

							return false;
						});

					}
				});

			});
	},

	filterTyres: function() {
		var dropdowns = $('.filterDropdowns');
		var all = $('#all_sel option');
		var selected = "";

		dropdowns.on('change', function(key,row) {
			selected = $(this).attr('name');

			var filterValue = "";
			var filterLength = 0;
			var values = [];
			var tmp = "";

			dropdowns.each(function(key,row) {
				tmp = $(row).val();
				filterValue += tmp + ' / ';
			});

			filterValue = filterValue.slice(0,-3).split(' / ');

			all.each(function(key,row) {
				var current = $(row).val().split(' / ');
				var add = true;
				for(var x =0; x < filterValue.length; x++) {
					if(filterValue[x] != '') {
						if((current[x] != filterValue[x]) && (add == true)) {
							add = false;
						}
					}
				}

				if(add == true) {
					values.push(current);
				}

		});

			// Empty dropdowns
			dropdowns.each(function(key,row) {
				var row = $(row);
				var name = row.attr('name');
				if(name != selected) {
					var selected_dropdown = $('#' + name);
					var selected_value = selected_dropdown.val();
					if(selected_value == '')
					{
						var tmp = '<option value="">Any</option>';
						selected_dropdown.html(tmp);
						for(var x = 0; x < values.length; x++) {
							if(selected_dropdown.find('option[value="'+values[x][key]+'"]').length == 0) {
									tmp = '<option value="'+values[x][key]+'"';
									if(values[x][key] == selected_value) {
										tmp += ' selected="selected"';
									}
									tmp += '>'+values[x][key]+'</option>';
								selected_dropdown.append(tmp);
							}
						}
					}
				}
			});

		});


	},

	init: function() {
		pawLibrary.mobileNav();

		if($('.filterDropdowns').length > 0)
		{
			pawLibrary.filterTyres();
			if($('.filterDropdowns option[selected="selected"]').length > 0) {
				$('.filterDropdowns option[selected="selected"]:first').trigger('change');
			}
		}

		if( $('#stockists_list').length > 0)
		{
			pawLibrary.setMarkers();
		}

		var inlineEnquiries = $('.enquiryDialog');
		if(inlineEnquiries.length > 0) {
			pawLibrary.configureInlineEnquiries();
		}

		if( $('#location_txt').length > 0 )
		{
			var input = document.getElementById('location_txt');
			var countryRestrict = {'country': 'uk'};
			var options = {
			  types: ['geocode'],
        componentRestrictions: countryRestrict
			};
			autocomplete = new google.maps.places.Autocomplete(input, options);
		}

		if( $('#currency-selector').length > 0 )
		{
			pawLibrary.triggerCurrencies();
		}

		if( $('#gallery-img-thumb').length > 0 ) {
			$('#productFeatureImg').on('cycle-next cycle-prev', function(e, opts) {
				$('#gallery-img-thumb').cycle('goto', opts.currSlide);
			});

			$('#gallery-img-thumb .thumb-outter').click(function(){
			    var index = $('#gallery-img-thumb').data('cycle.API').getSlideIndex(this);
			    $('#productFeatureImg').cycle('goto', index);
			});

		}

	}

}

$(window).on('load', function() {
	pawLibrary.init();
});
